import tableStyles from "../../../styles/table.module.css";

const Table = ({
  children,
  classes
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
}) => {
  return (
    <table className={`${tableStyles.table} ${classes || ""}`}>
      {children}
    </table>
  );
};

export default Table;

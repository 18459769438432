import axios, { AxiosError } from "axios";
import { ErrorData } from "../types/common";
import { setKcObject } from "../store/auth";

export const baseURL = window._env_.REACT_APP_USER_MANAGEMENT_API_URL;

export const API_PATHS = {
  getUsers: "/users",
  deleteUser: (userRef: string) => `/user/${userRef}`,
  addNewUser: "/user",
  getRoles: "/roles",
  getCompanies: "/companies",
  getProducts: "/products"
} as const;

const api = axios.create({
  baseURL
});

// To do: add return type if needed
export const postData = async <T>(url: string, data: T): Promise<any> => {
  try {
    await api.post(url, data);
  } catch (err: any) {
    return { error: err?.response?.data?.reason || "An error occurred" };
  }
};

export const patchData = async <T>(url: string, data: T): Promise<any> => {
  try {
    await api.patch(url, data);
  } catch (err: any) {
    return { error: err?.response?.data?.message || "An error occurred" };
  }
};

export const deleteData = async (url: string): Promise<any> => {
  try {
    await api.delete(url);
  } catch (err: any) {
    return { error: err?.response?.data?.message || "An error occurred" };
  }
};

export function addKeycloakInterceptorWithKeycloakInstance(
  keycloakObject: any
) {
  api.interceptors.request.use(
    (config) => requestInterceptorTokenHandler(config, keycloakObject),

    (request) => {
      return request;
    }
  );
}

/* The updateToken function is a keycloak function that checks if the token expires within any given
timeframe (in seconds) - and if so, updates the token. */
const requestInterceptorTokenHandler = async (
  config: any,
  keycloakObject: any
) => {
  try {
    const MIN_VALIDITY_SECS = 10;
    await keycloakObject.updateToken(MIN_VALIDITY_SECS);
    const token = keycloakObject.token;
    setKcObject(keycloakObject);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    try {
      // getting a new refresh token by logging in user as it failed to refresh access token.
      keycloakObject.login();
    } catch (e) {
      // getting new refresh token failed, so logging user out.
      keycloakObject.logout({
        redirectUri: window._env_.REACT_APP_URL
      });
    }
  }
  return config;
};

export default api;

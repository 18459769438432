import subNavigationStyles from "../../styles/subPageNavigation.module.css";

export default function SubPageNavigation({
  children,
  classes
}: {
  children?: React.ReactNode | React.ReactNode[];
  classes?: string;
}) {
  return (
    <article
      className={`${subNavigationStyles.subPageNavigation} ${classes || ""}`}
    >
      {children}
    </article>
  );
}

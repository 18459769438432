import { ReactComponent as AnalystIcon } from "../assets/icons/bagstage_analyst_small.svg";
import { ReactComponent as OperatorIcon } from "../assets/icons/bagstage_operator_small.svg";
import { ReactComponent as QTBMonitorIcon } from "../assets/icons/bagstage_qtb_monitor_small.svg";

// get icon for chip
export const ChipIcon = ({
  chipRef
}: {
  chipRef: string;
}): React.ReactElement => {
  switch (chipRef) {
    case "arrival-analyst":
    case "departure-analyst":
      return <AnalystIcon />;
    case "arrival-operator":
      return <OperatorIcon />;
    case "transfer-qtb":
      return <QTBMonitorIcon />;
    default:
      return <></>;
  }
};

// get chip bg and font colors
export const getChipColors = (
  chipRef: string
): { chipBgColor: string; chipLabelColor: string } => {
  switch (chipRef) {
    case "arrival-analyst":
    case "departure-analyst":
      return {
        chipBgColor: "var(--color-yellow-light)",
        chipLabelColor: "var(--color-yellow-dark)"
      };
    case "arrival-operator":
      return {
        chipBgColor: "var(--color-green-light)",
        chipLabelColor: "var(--color-green-dark)"
      };
    case "transfer-qtb":
      return {
        chipBgColor: "var(--color-blue-light)",
        chipLabelColor: "var(--color-blue-dark)"
      };
    default:
      return {
        chipBgColor: "var(--color-grey-scale-1)",
        chipLabelColor: "var(--color-black)"
      };
  }
};

import { ReactComponent as CloseIcon } from "../../../assets/icons/bagstage_close.svg";
import modalStyles from "../../../styles/modal.module.css";
import ModalTitle from "./ModalTitle";
import IconButton from "../buttons/IconButton";

export default function ModalHeader({
  modalHeaderTitle,
  onCloseModal
}: {
  modalHeaderTitle: string;
  onCloseModal: () => void;
}) {
  return (
    <div className={modalStyles.modalHeader}>
      <ModalTitle titleContent={modalHeaderTitle} />

      <IconButton
        classes={modalStyles.closeButton}
        variant="iconOnly"
        onClick={onCloseModal}
        icon={<CloseIcon />}
      />
    </div>
  );
}

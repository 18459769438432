import { createSlice } from "@reduxjs/toolkit";
import { Company } from "../../types/users.types";
import { getCompanies } from "./action";

interface CompaniesSlice {
  companies?: Company[];
  error?: Error | null;
}

const initialState: CompaniesSlice = {
  error: null
};

const companiesSlice = createSlice({
  name: "companies-slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanies.pending, (state) => {
      delete state.error;
      delete state.companies;
    });
    builder.addCase(getCompanies.rejected, (state, action) => {
      state.error = action.error as Error;
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.companies = action.payload;
      }
    });
  }
});

export default companiesSlice.reducer;

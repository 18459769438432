import { useEffect } from "react";
import modalStyles from "../../../styles/modal.module.css";

export default function Modal({
  classes,
  children,
  width
}: {
  classes?: string;
  children: React.ReactNode | React.ReactNode[];
  width?: string;
}) {
  useEffect(() => {
    // stop scroll on body when modal is open
    document.body.classList.add("overflow-hidden");

    // allow scroll when modal unmounts/closes
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div className={modalStyles.modalOverlay}>
      <div
        className={`${modalStyles.modal} ${classes || ""}`}
        style={{ width: `${width}px` }}
      >
        {children}
      </div>
    </div>
  );
}

import { Product, ProductCategory } from "../types/users.types";
import { productCategories } from "../utils/productsUtil";

class ProductService {
  static categorizeProducts = (products: Product[]) => {
    const arrivalProducts = products.filter((product) =>
      product.productRef.startsWith(productCategories.arrival.categoryRef)
    );

    const departureProducts = products.filter((product) =>
      product.productRef.startsWith(productCategories.departure.categoryRef)
    );

    const transferProducts = products.filter((product) =>
      product.productRef.startsWith(productCategories.transfer.categoryRef)
    );

    return {
      arrivalProducts,
      departureProducts,
      transferProducts
    };
  };

  static updatedProductCategoriesList = (
    productCategoriesList: ProductCategory[]
  ) => {
    return productCategoriesList.filter(
      (category) => category.optionsList && category.optionsList.length > 0
    );
  };
}

export default ProductService;

import tableStyles from "../../../styles/table.module.css";

const TableRow = ({
  children,
  classes
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
}) => {
  return (
    <tr className={`${tableStyles.tableRow} ${classes || ""}`}>{children}</tr>
  );
};

export default TableRow;

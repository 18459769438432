import { t } from "i18next";
import { productCategories, productType } from "../../utils/productsUtil";
import StandardButton from "../UI/buttons/StandardButton";
import Chip from "../UI/Chip";
import InputFieldNumber from "../UI/InputFields/InputFieldNumber";
import InputFieldString from "../UI/InputFields/InputFieldString";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "../UI/modal";
import MultiSelectDropdown from "../UI/MultiSelectDropdown/MultiSelectDropdown";
import MultiSelectDropdownOptions from "../UI/MultiSelectDropdown/MultiSelectDropdownOptions";
import DropdownOptions from "../UI/SingleSelectDropdown/DropdownOptions";
import { useEffect, useState } from "react";
import Dropdown from "../UI/SingleSelectDropdown/Dropdown";
import { getRoles } from "../../store/roles/action";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  Company,
  Product,
  ProductCategory,
  Role
} from "../../types/users.types";
import { getCompanies } from "../../store/companies/action";
import { getProducts } from "../../store/products/action";
import ProductService from "../../service/productService";
import { emailRegex } from "../../utils/regex";
import UserService from "../../service/userService";
import { getUsers } from "../../store/users/action";

export default function CreateNewUser({
  setIsNewUserModalOpen
}: {
  setIsNewUserModalOpen: (isNewUserModalOpen: boolean) => void;
}) {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<productType[]>([]);
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [disableCreate, setDisableCreate] = useState(true);
  const [apiError, setApiError] = useState<string>();

  const dispatch = useAppDispatch();
  const { roles } = useAppSelector((state) => state.rolesSlice);
  const { companies } = useAppSelector((state) => state.companiesSlice);
  const { arrivalProducts, departureProducts, transferProducts } =
    useAppSelector((state) => state.productsSlice);

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getCompanies());
    dispatch(getProducts());
  }, [dispatch]);

  // disable create button if any of the fields is empty or in case of email error.
  useEffect(() => {
    setDisableCreate(
      !username?.trim() ||
        !email ||
        Boolean(emailError) ||
        !phoneNumber ||
        !selectedCompany ||
        !selectedRole ||
        selectedProducts.length === 0
    );
  }, [
    username,
    email,
    emailError,
    phoneNumber,
    selectedCompany,
    selectedProducts,
    selectedRole
  ]);

  const productCategoriesList: ProductCategory[] = [
    {
      categoryName: productCategories.arrival.categoryName,
      optionsList: arrivalProducts!
    },
    {
      categoryName: productCategories.departure.categoryName,
      optionsList: departureProducts!
    },
    {
      categoryName: productCategories.transfer.categoryName,
      optionsList: transferProducts!
    }
  ];

  const categorisedProducts = ProductService.updatedProductCategoriesList(
    productCategoriesList
  );

  const handleSelectProduct = (product: productType) => {
    if (
      selectedProducts.some(
        (option) => option.productRef === product.productRef
      )
    ) {
      setSelectedProducts((prev) =>
        prev.filter((option) => option.productRef !== product.productRef)
      );
    } else {
      setSelectedProducts((prev) => [...prev, product]);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setApiError("");
    if (!emailRegex.test(e.target.value)) {
      setEmailError(t("please-enter-a-valid-email-address"));
    } else {
      setEmailError("");
    }
  };

  const handleAddNewUser = async () => {
    const userData = {
      username: username,
      mailAddress: email,
      companyRef: selectedCompany?.companyRef,
      phoneNumber: phoneNumber,
      productAssignments: selectedProducts.map((product) => ({
        productRef: product.productRef,
        roleRef: selectedRole?.roleRef
      }))
    };

    const response = await UserService.addNewUser(userData);

    if (response && "error" in response) {
      setApiError(response.error);
    } else {
      dispatch(getUsers());
      setIsNewUserModalOpen(false);
    }
  };

  return (
    <Modal classes="w-[600px]">
      <ModalHeader
        modalHeaderTitle={t("new-user")}
        onCloseModal={() => setIsNewUserModalOpen(false)}
      />
      <ModalContent>
        <InputFieldString
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          label={t("name")}
          placeholder={t("enter-user-name")}
          required
        />
        <InputFieldString
          value={email}
          onChange={handleEmailChange}
          label={t("email")}
          required
          placeholder={t("enter-email-address")}
          helperText={emailError || apiError}
          error={Boolean(emailError) || Boolean(apiError)}
        />
        <InputFieldNumber
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          label={t("mobile-number")}
          placeholder={t("enter-number")}
          required
        />
        <Dropdown
          label={t("company")}
          placeholder={t("select-company")}
          required
          selectedOption={selectedCompany?.companyName}
        >
          {companies && (
            <DropdownOptions<Company>
              options={companies}
              handleChange={(company: Company) => setSelectedCompany(company)}
              getKey={(company: Company) => company.companyRef}
              selectedOption={selectedCompany}
              displayOption={(company: Company) => company.companyName}
            />
          )}
        </Dropdown>
        <MultiSelectDropdown
          label={t("product-access")}
          placeholder={t("select-products")}
          required
          selectedOptions={selectedProducts?.map((product: Product) => (
            <Chip
              key={product.productRef}
              chipLabel={product.productName}
              chipRef={product.productRef}
            />
          ))}
        >
          {categorisedProducts && (
            <MultiSelectDropdownOptions<Product>
              categories={categorisedProducts}
              selectedOptions={selectedProducts}
              handleSelectOption={handleSelectProduct}
              displayOption={(product: Product) => product.productName}
              getKey={(product: Product) => product.productRef}
            />
          )}
        </MultiSelectDropdown>
        <Dropdown
          label={t("user-role")}
          placeholder={t("select-access-level")}
          required
          selectedOption={selectedRole?.roleName}
        >
          {roles && (
            <DropdownOptions<Role>
              options={roles}
              handleChange={(role: Role) => setSelectedRole(role)}
              getKey={(role: Role) => role.roleRef}
              selectedOption={selectedRole}
              displayOption={(role: Role) => role.roleName}
            />
          )}
        </Dropdown>
      </ModalContent>
      <ModalFooter>
        <StandardButton onClick={() => setIsNewUserModalOpen(false)}>
          {t("cancel")}
        </StandardButton>
        <StandardButton
          onClick={handleAddNewUser}
          variant="primary"
          disable={disableCreate || Boolean(apiError)}
        >
          {t("create")}
        </StandardButton>
      </ModalFooter>
    </Modal>
  );
}

import { useRef, useState } from "react";
import { ReactComponent as MandatoryIcon } from "../../../assets/icons/bagstage_mandatory_star.svg";
import { useClickOutside } from "../../../hooks/useClickOutside";
import dropdownStyles from "../../../styles/dropdown.module.css";
import textfieldStyles from "../../../styles/inputfield.module.css";

export default function Dropdown({
  label,
  placeholder,
  error,
  required,
  selectedOption,
  disable,
  children,
  helperText
}: {
  label: string;
  children: React.ReactNode;
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  selectedOption?: string;
  disable?: boolean;
  helperText?: string;
}) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <label htmlFor={label} className={dropdownStyles.dropdownMain}>
      <p className={dropdownStyles.dropdownLabel}>
        {label}
        {required && <MandatoryIcon />}
      </p>
      <div ref={dropdownRef}>
        <button
          disabled={disable}
          className={`
            ${dropdownStyles.dropdown}
             ${
               isOpen
                 ? dropdownStyles.dropdownIconOpen
                 : dropdownStyles.dropdownIconClose
             }
            ${selectedOption ? "" : dropdownStyles.dropdownPlaceholder}
            ${error ? dropdownStyles.dropdownError : ""}
            `}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {selectedOption ? selectedOption : placeholder}
        </button>
        {isOpen && (
          <ul
            className={dropdownStyles.dropdownList}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {children}
          </ul>
        )}
      </div>
      <span
        className={`
          ${textfieldStyles.helperText}
          ${error ? textfieldStyles.helperTextError : ""}
          `}
      >
        {helperText}
      </span>
    </label>
  );
}

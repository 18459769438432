export const userTableDimensions = {
  name: "236",
  email: "210",
  mobileNumber: "110",
  company: "136",
  productAccess: "210",
  userRole: "160",
  lastVisited: "100"
};

export const userTableHeader = [
  { label: "name", width: userTableDimensions.name },
  { label: "email", width: userTableDimensions.email },
  { label: "mobile-number", width: userTableDimensions.mobileNumber },
  { label: "company", width: userTableDimensions.company },
  { label: "product-access", width: userTableDimensions.productAccess },
  { label: "user-role", width: userTableDimensions.userRole },
  { label: "last-visited", width: userTableDimensions.lastVisited }
];

import { API_PATHS, deleteData, postData } from "./api";

export default class UserService {
  static deleteAUser = (userRef: string) => {
    return deleteData(`${API_PATHS.deleteUser(userRef)}`);
  };

  static addNewUser = (userData: any) => {
    return postData(API_PATHS.addNewUser, userData);
  };
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";

export enum actionTypes {
  getProducts = "getProducts"
}

export const getProducts = createAsyncThunk(
  actionTypes.getProducts,
  async () => {
    const response = await api.get(API_PATHS.getProducts);
    return response.data;
  }
);

import i18n from "i18next";
import { TFunction } from "i18next";
import { capitalizeFirstLetter } from "../utils/commonUtil";

class TimeService {
  static getCurrentTime(): string {
    return new Date().toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit"
    });
  }

  static returnTwoDigitTimeString(timeValue: number) {
    if (timeValue < 10) {
      return `0${timeValue}`;
    } else {
      return timeValue.toString();
    }
  }

  // Formats a timestamp into a date string for traffic data summary.
  static formatDateForTrafficDataSummary(timestamp: string): string {
    // Create a Date object from the timestamp
    const date = new Date(timestamp);

    // Define options for date and time formatting
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false // Use 24-hour format
    };

    // Format the date using the Intl.DateTimeFormat API
    const formattedDate = new Intl.DateTimeFormat("default", options).format(
      date
    );
    return formattedDate;
  }

  static formattedDateForHeader(t: TFunction): string {
    let weekday, day, month, year;
    const currentDate = new Date();

    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    const formattedDate = currentDate.toLocaleDateString(
      i18n.language,
      options
    );

    const dateParts = formattedDate.split(/[\s,]+/);

    if (i18n.language.startsWith("da")) {
      // Danish
      [weekday, , day, month, year] = dateParts;
      day = day.replace(".", "");
    } else {
      // English
      [weekday, month, day, year] = dateParts;
    }

    const capitalizedWeekday = capitalizeFirstLetter(t(`${weekday}`));
    const capitalizedMonth = capitalizeFirstLetter(t(`${month}`));

    return `${capitalizedWeekday} ${day} ${capitalizedMonth}, ${year}`;
  }
}

export default TimeService;

import { ChipIcon, getChipColors } from "../../service/chipService";
import chipStyles from "../../styles/chip.module.css";

export default function Chip({
  chipLabel,
  chipRef
}: {
  chipLabel: string;
  chipRef: string;
}) {
  const { chipBgColor, chipLabelColor } = getChipColors(chipRef);

  return (
    <div className={chipStyles.chip} style={{ backgroundColor: chipBgColor }}>
      <ChipIcon chipRef={chipRef} />
      <p style={{ color: chipLabelColor }}>{chipLabel}</p>
    </div>
  );
}

import Checkbox from "../Checkbox";
import dropdownStyles from "../../../styles/dropdown.module.css";

export default function MultiSelectDropdownOptions<T>({
  categories,
  selectedOptions,
  handleSelectOption,
  getKey,
  displayOption,
  disabledOptions
}: {
  categories: { categoryName: string; optionsList: T[] }[];
  selectedOptions: T[];
  handleSelectOption: (value: T) => void;
  getKey: (option: T) => string;
  displayOption: (option: T) => string;
  disabledOptions?: T[];
}) {
  return (
    <>
      {categories.map((category) => (
        <div
          key={category.categoryName}
          className="flex flex-col gap-spacing-xs"
        >
          <p className={dropdownStyles.categoryName}>{category.categoryName}</p>
          <ul className="flex flex-col gap-spacing-xs">
            {category.optionsList?.map((option) => (
              <li key={getKey(option)}>
                <Checkbox
                  id={getKey(option)}
                  check={selectedOptions.some(
                    (selectedOption) =>
                      getKey(selectedOption) === getKey(option)
                  )}
                  onChange={() => {
                    handleSelectOption(option);
                  }}
                  text={displayOption(option)}
                  disable={disabledOptions?.some(
                    (value) => getKey(value) === getKey(option)
                  )}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}

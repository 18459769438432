import cardStyles from "../../../styles/card.module.css";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";

export interface cardListItem {
  applicationTitle: string;
  redirectUrl: string;
  icon: React.ReactNode;
}

export default function CardListItem({
  cardItems
}: {
  cardItems: cardListItem;
}) {
  const { redirectUrl, icon, applicationTitle } = cardItems;
  const { isLogin } = useAppSelector((state) => state.authenticationSlice);

  return isLogin ? (
    <Link to={redirectUrl} className={cardStyles.cardListItem}>
      {icon}
      {applicationTitle}
    </Link>
  ) : (
    <div className={`${cardStyles.cardListItem} cursor-not-allowed opacity-30`}>
      {icon}
      {applicationTitle}
    </div>
  );
}

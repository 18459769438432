import headerStyles from "../../styles/header.module.css";
import { ReactComponent as HomeIcon } from "../../assets/icons/bagstage_home.svg";
import { useTranslation } from "react-i18next";
import TimeService from "../../service/TimeService";
import Clock from "./Clock";
import IconLinkButton from "./buttons/IconLinkButton";

export default function Header({
  breadcrumbText,
  pageTitle
}: {
  breadcrumbText: string;
  pageTitle: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={headerStyles.header}>
      <div className={headerStyles.headerSection}>
        <IconLinkButton
          to={window._env_.REACT_APP_URL!} //TODO: update URL after moving code to user management repo
          icon={<HomeIcon />}
          variant="primary"
        />
        <div className={headerStyles.headerTextContent}>
          <p className={headerStyles.breadcrumbText}>{breadcrumbText}</p>
          <p className={headerStyles.currentPageText}>{pageTitle}</p>
        </div>
      </div>
      <div className={headerStyles.headerSection}>
        <p className={headerStyles.dateTimeText}>
          {TimeService.formattedDateForHeader(t)}
        </p>
        <Clock />
      </div>
    </div>
  );
}

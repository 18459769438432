import dropdownStyles from "../../../styles/dropdown.module.css";
import { ReactComponent as BlueCheck } from "../../../assets/icons/bagstage_blue_check.svg";

export default function DropdownOptions<T>({
  options,
  getKey,
  selectedOption,
  displayOption,
  handleChange,
  disabledOptions
}: {
  options: T[];
  selectedOption: T | null;
  getKey: (option: T) => string;
  displayOption: (option: T) => string;
  handleChange: (value: T) => void;
  disabledOptions?: T[];
}) {
  return (
    <>
      {options?.map((item) => (
        <li
          key={getKey(item)}
          className={`
            ${dropdownStyles.dropdownOption}
            ${
              selectedOption && getKey(selectedOption) === getKey(item)
                ? dropdownStyles.dropdownOptionActive
                : ""
            }
          `}
          onClick={() => handleChange(item)}
        >
          <button
            className={dropdownStyles.dropdownOptionItem}
            disabled={disabledOptions?.some(
              (value) => getKey(value) === getKey(item)
            )}
          >
            {displayOption(item)}
            {selectedOption && getKey(selectedOption) === getKey(item) && (
              <BlueCheck />
            )}
          </button>
        </li>
      ))}
    </>
  );
}

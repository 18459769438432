import checkboxStyles from "../../styles/checkbox.module.css";
import { useEffect } from "react";
import { ReactComponent as Checkmark } from "../../assets/icons/bagstage_checkbox_tick.svg";
import { ReactComponent as BlueCheck } from "../../assets/icons/bagstage_blue_check.svg";
import dropdownStyles from "../../styles/dropdown.module.css";

export default function Checkbox({
  id,
  classes,
  check,
  disable,
  onChange,
  text
}: {
  id: string;
  classes?: string;
  disable?: boolean;
  check: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string;
}) {
  // Update the stroke color of the checkbox tick icon based on disable state
  useEffect(() => {
    const labelElement = document.getElementById(id);
    const path = labelElement?.querySelectorAll("path");
    if (path && path[0]) {
      path[0].setAttribute(
        "stroke",
        disable ? "var(--color-grey-scale-3)" : "var(--color-white)"
      );
    }
  }, [disable, id]);

  return (
    <label
      id={id}
      className={`
        ${checkboxStyles.customCheckbox}
        ${text ? `${checkboxStyles.checkboxTextOption} ` : ""}
        ${text && check ? dropdownStyles.dropdownOptionActive : ""}
      `}
    >
      <div
        className={`${checkboxStyles.customCheckbox} ${
          text ? "gap-spacing-m" : ""
        }`}
      >
        <input
          type="checkbox"
          checked={check}
          disabled={disable}
          className={checkboxStyles.checkbox}
          onChange={onChange}
        />
        <span className={`${checkboxStyles.checkmark} ${classes || ""}`}>
          {check && <Checkmark />}
        </span>

        <span className={checkboxStyles.checkboxText}>{text}</span>
      </div>
      {text && check && <BlueCheck />}
    </label>
  );
}

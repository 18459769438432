import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authenticationSlice from "./auth";
import usersSlice from "./users";
import rolesSlice from "./roles";
import companiesSlice from "./companies";
import productsSlice from "./products";

const store = configureStore({
  reducer: combineReducers({
    authenticationSlice,
    usersSlice,
    rolesSlice,
    companiesSlice,
    productsSlice
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // to avoid error while storing authenticationSlice.kcObject.
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;

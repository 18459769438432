import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as MenuIcon } from "../../assets/icons/bagstage_menu.svg";
import { DateService } from "../../service/dateService";
import UserService from "../../service/userService";
import { useAppDispatch } from "../../store/hooks";
import { getUsers } from "../../store/users/action";
import tooltipStyles from "../../styles/tooltip.module.css";
import userManagementStyles from "../../styles/userManagementTable.module.css";
import { User } from "../../types/users.types";
import {
  userTableDimensions,
  userTableHeader
} from "../../utils/userManagementUtil";
import Checkbox from "../UI/Checkbox";
import Chip from "../UI/Chip";
import Table from "../UI/table/Table";
import TableCell from "../UI/table/TableCell";
import TableHeaderCell from "../UI/table/TableHeaderCell";
import TableRow from "../UI/table/TableRow";
import Tooltip from "../UI/Tooltip";
import StandardButton from "../UI/buttons/StandardButton";

export default function UserManagementTable({ users }: { users: User[] }) {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const handleSelectUsers = (userRef: string) => {
    if (selectedUsers.includes(userRef)) {
      setSelectedUsers((prev) => prev.filter((item) => item !== userRef));
    } else {
      setSelectedUsers((prev) => [...prev, userRef]);
    }
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.userRef));
    }
  };

  const handleDeleteUser = async (userRef: string) => {
    let result;
    if (selectedUsers.includes(userRef)) {
      const deleteRulesetPromises = selectedUsers.map(
        async (rulesetRef: string) => {
          return await UserService.deleteAUser(rulesetRef);
        }
      );

      await Promise.all(deleteRulesetPromises);
    } else {
      result = await UserService.deleteAUser(userRef);
    }
    if (result && "error" in result) {
      // TODO: show error
    } else {
      dispatch(getUsers());
    }
  };

  const handleEdit = () => {
    // TODO: handle edit
  };

  const handleResetPassword = () => {
    // TODO: handle reset password
  };

  return (
    <Table>
      <thead>
        <TableRow classes={userManagementStyles.userTableRowHeader}>
          <TableHeaderCell width="24" classes="justify-center">
            <Checkbox
              check={selectedUsers.length === users.length}
              onChange={handleSelectAllUsers}
              id="users-header"
            />
          </TableHeaderCell>
          {userTableHeader.map((info) => (
            <TableHeaderCell key={info.label} width={`${info.width}`}>
              {t(info.label)}
            </TableHeaderCell>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {users?.map((user) => (
          <TableRow key={user.userRef}>
            <TableCell width="24">
              <Checkbox
                check={selectedUsers?.includes(user.userRef)}
                onChange={() => handleSelectUsers(user.userRef)}
                id={user.userRef}
              />
            </TableCell>
            <TableCell
              black
              width={userTableDimensions.name}
              classes={userManagementStyles.userName}
            >
              {user.userName}
            </TableCell>
            <TableCell width={userTableDimensions.email}>
              {user.mailAddress}
            </TableCell>
            <TableCell width={userTableDimensions.mobileNumber}>
              {user.phoneNumber}
            </TableCell>
            <TableCell width={userTableDimensions.company}>
              {user.company.companyName}
            </TableCell>
            <TableCell width={userTableDimensions.productAccess}>
              {user.productAssignments.map((info) => (
                <div key={info.product.productRef}>
                  <Chip
                    chipRef={info.product.productRef}
                    chipLabel={t(info.product.productName)}
                  />
                </div>
              ))}
            </TableCell>
            <TableCell width={userTableDimensions.userRole}>
              {user?.productAssignments[0]?.role?.roleName}
            </TableCell>
            <TableCell width={userTableDimensions.lastVisited}>
              {user.latestLogin && DateService.getLastVisited(user.latestLogin)}
            </TableCell>
            <TableCell width="16" classes="flex-1 items-end">
              {/* todo: add translation */}
              <Tooltip
                icon={<MenuIcon />}
                position="bottom"
                classes={tooltipStyles.tooltipContent}
              >
                <StandardButton
                  onClick={handleEdit}
                  classes={tooltipStyles.tooltipOption}
                  variant="tertiary"
                >
                  {t("edit")}
                </StandardButton>
                <StandardButton
                  onClick={handleResetPassword}
                  classes={tooltipStyles.tooltipOption}
                  variant="tertiary"
                >
                  {t("reset-password")}
                </StandardButton>

                <StandardButton
                  onClick={() => handleDeleteUser(user.userRef)}
                  classes={tooltipStyles.tooltipOption}
                  variant="tertiary"
                >
                  {t("delete-user", {
                    number: selectedUsers?.includes(user.userRef)
                      ? selectedUsers.length
                      : 1
                  })}
                </StandardButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}

import modalStyles from "../../../styles/modal.module.css";

export default function ModalFooter({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <div className={modalStyles.modalFooter}>
      <div className={modalStyles.modalFooterContent}>{children}</div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/UI/Header";
import Loader from "../components/UI/Loader";
import PageWrapper from "../components/UI/PageWrapper";
import StandardButton from "../components/UI/buttons/StandardButton";
import SubPageNavigation from "../components/UI/SubPageNavigation";
import UserManagementTable from "../components/userManagement/UserManagementTable";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getUsers } from "../store/users/action";
import CreateNewUser from "../components/userManagement/CreateNewUser";
import SubPageWrapper from "../components/UI/SubPageWrapper";

export default function UserManagement() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state.usersSlice);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <PageWrapper>
      <Header
        breadcrumbText={t("home-page")}
        pageTitle={t("user-management")}
      />
      <SubPageNavigation>
        <StandardButton
          variant="primary"
          onClick={() => setIsNewUserModalOpen((prev) => !prev)}
        >
          {t("new-user")}
        </StandardButton>
      </SubPageNavigation>
      <SubPageWrapper>
        {users ? <UserManagementTable users={users} /> : <Loader />}
        {isNewUserModalOpen && (
          <CreateNewUser setIsNewUserModalOpen={setIsNewUserModalOpen} />
        )}
      </SubPageWrapper>
    </PageWrapper>
  );
}

import { ReactComponent as BagstageLogo } from "../assets/bagstage_logo.svg";
import { ReactComponent as ArrivalIcon } from "../assets/icons/bagstage_black_flight_landing.svg";
import { ReactComponent as TakeOffIcon } from "../assets/icons/bagstage_flight_takeoff.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/bagstage_operator.svg";
import { ReactComponent as BarChartIcon } from "../assets/icons/bagstage_analyst.svg";
import { ReactComponent as ViewOnlyIcon } from "../assets/icons/bagstage_view_only.svg";
import { ReactComponent as QTBMonitorIcon } from "../assets/icons/bagstage_qtb_monitor.svg";
import { ReactComponent as TransferIcon } from "../assets/icons/bagstage_swap_horizontal.svg";
import PageWrapper from "../components/UI/PageWrapper";
import HomePageCard from "../components/homepage/HomePageCard";
import { cardListItem } from "../components/UI/Cards/CardListItem";
import Footer from "../components/homepage/Footer";
import StyledIcon from "../components/UI/StyledIcon";
import homePageStyles from "../styles/homePage.module.css";
import iconStyles from "../styles/icon.module.css";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { t } = useTranslation();
  const { login } = useAuth();

  const arrivalLinks: cardListItem[] = [
    {
      applicationTitle: t("operator-tool"),
      redirectUrl: "/",
      icon: (
        <StyledIcon icon={<GlobeIcon />} classes={iconStyles.globeIconBorder} />
      )
    },
    {
      applicationTitle: t("analyst-tool"),
      redirectUrl: window._env_.REACT_APP_ARRIVAL_ANALYST_URL!,
      icon: (
        <StyledIcon
          icon={<BarChartIcon />}
          classes={iconStyles.barChartIconBorder}
        />
      )
    },
    {
      applicationTitle: t("view-only-operator-tool"),
      redirectUrl: "/",
      icon: (
        <StyledIcon
          icon={<ViewOnlyIcon />}
          classes={iconStyles.viewOnlyIconBorder}
        />
      )
    }
  ];

  const departureLinks: cardListItem[] = [
    {
      applicationTitle: t("analyst-tool"),
      redirectUrl: "/",
      icon: (
        <StyledIcon
          icon={<BarChartIcon />}
          classes={iconStyles.barChartIconBorder}
        />
      )
    }
  ];

  const transferLinks: cardListItem[] = [
    {
      applicationTitle: t("qtb-monitor"),
      redirectUrl: "/",
      icon: (
        <StyledIcon
          icon={<QTBMonitorIcon />}
          classes={iconStyles.qtbMonitorIconBorder}
        />
      )
    }
  ];

  return (
    <PageWrapper classes={homePageStyles.pageWrapper}>
      <BagstageLogo className={homePageStyles.bagstageLogo} />

      <section className={homePageStyles.cardSection}>
        <HomePageCard
          icon={<ArrivalIcon />}
          title={t("arrival")}
          cardItems={arrivalLinks}
        />

        <HomePageCard
          icon={<TakeOffIcon />}
          title={t("departure")}
          cardItems={departureLinks}
        />

        <HomePageCard
          icon={<TransferIcon />}
          title={t("transfer")}
          cardItems={transferLinks}
        />
      </section>

      <Footer isLoggedIn={login} />
    </PageWrapper>
  );
}

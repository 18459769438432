import tableStyles from "../../../styles/table.module.css";

const TableCell = ({
  children,
  width,
  classes,
  black
}: {
  children: React.ReactNode | React.ReactNode[];
  width?: string;
  classes?: string;
  black?: boolean;
}) => {
  return (
    <td
      className={`
        ${tableStyles.tableCell}
        ${black ? tableStyles.tableBlackText : tableStyles.tableGreyText}
        ${classes || ""}
        `}
      style={{ width: width ? `${width}px` : "140px" }}
    >
      {children}
    </td>
  );
};

export default TableCell;

export type productType = {
  productRef: string;
  productName: string;
};

// for internal use
export const productCategories = {
  arrival: {
    categoryName: "Arrival",
    categoryRef: "arrival"
  },
  departure: {
    categoryName: "Departure",
    categoryRef: "departure"
  },
  transfer: {
    categoryName: "Transfer",
    categoryRef: "transfer"
  }
};

import { useEffect, useRef, useState } from "react";
import dropdownStyles from "../../../styles/dropdown.module.css";
import { ReactComponent as MandatoryIcon } from "../../../assets/icons/bagstage_mandatory_star.svg";
import { useClickOutside } from "../../../hooks/useClickOutside";
import textfieldStyles from "../../../styles/inputfield.module.css";

export default function MultiSelectDropdown({
  label,
  placeholder,
  error,
  required,
  disable,
  children,
  selectedOptions,
  helperText
}: {
  label: string;
  children: React.ReactNode;
  selectedOptions: React.ReactElement[];
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  disable?: boolean;
  helperText?: string;
}) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [positionTop, setPositionTop] = useState(false); // New state to manage position

  useClickOutside(dropdownRef, () => setIsOpen(false));

  // to invert the position of the options if there's less space below.
  useEffect(() => {
    if (isOpen && optionsListRef.current && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const listRect = optionsListRef.current.getBoundingClientRect();
      const availableSpaceBelow = window.innerHeight - dropdownRect.bottom;
      const availableSpaceAbove = dropdownRect.top;

      if (
        listRect.height > availableSpaceBelow &&
        availableSpaceAbove > listRect.height
      ) {
        setPositionTop(true);
      } else {
        setPositionTop(false);
      }
    }
  }, [isOpen]);

  return (
    <label htmlFor={label} className={dropdownStyles.dropdownMain}>
      <p className={dropdownStyles.dropdownLabel}>
        {label}
        {required && <MandatoryIcon />}
      </p>
      <div ref={dropdownRef}>
        <button
          disabled={disable}
          className={`
            ${dropdownStyles.dropdown}
            ${
              isOpen
                ? dropdownStyles.dropdownIconOpen
                : dropdownStyles.dropdownIconClose
            }
            ${
              selectedOptions.length > 0
                ? ""
                : dropdownStyles.dropdownPlaceholder
            }
            ${error ? dropdownStyles.dropdownError : ""}
          `}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {selectedOptions.length > 0 ? selectedOptions : placeholder}
        </button>
        {isOpen && (
          <div
            ref={optionsListRef}
            className={dropdownStyles.dropdownList}
            style={{
              top: positionTop ? "auto" : "100%",
              bottom: positionTop ? "100%" : "auto"
            }}
          >
            {children}
          </div>
        )}
        <span
          className={`
          ${textfieldStyles.helperText}
          ${error ? textfieldStyles.helperTextError : ""}
          `}
        >
          {helperText}
        </span>
      </div>
    </label>
  );
}

import tableStyles from "../../../styles/table.module.css";

const TableHeaderCell = ({
  children,
  width,
  classes
}: {
  children: React.ReactNode | React.ReactNode[];
  width?: string;
  classes?: string;
}) => {
  return (
    <th
      className={`${tableStyles.tableDataHeader} ${classes || ""}`}
      style={{ width: width ? `${width}px` : "140px" }}
    >
      {children}
    </th>
  );
};

export default TableHeaderCell;

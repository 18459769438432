import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";
import { User } from "../../types/users.types";

export enum actionTypes {
  getUsers = "getUsers"
}

export const getUsers = createAsyncThunk(actionTypes.getUsers, async () => {
  const response = await api.get<User[]>(API_PATHS.getUsers);
  return response.data;
});

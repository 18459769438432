import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { NotFound } from "./pages/NotFound";
import UserManagement from "./pages/UserManagement";
import { addKeycloakInterceptorWithKeycloakInstance } from "./service/api";
import "./service/translations/translationService";
import { useAppSelector } from "./store/hooks";

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  {
    path: "/user-management",
    element: <UserManagement />
  },
  { path: "*", element: <NotFound /> }
]);

const App = () => {
  const { kcObject } = useAppSelector((state) => state.authenticationSlice);

  // To do - toggle when adding login
  useEffect(() => {
    if (kcObject) {
      addKeycloakInterceptorWithKeycloakInstance(kcObject);
    }
  }, [kcObject]);

  return (
    <main>
      <RouterProvider router={router} />
    </main>
  );
};
export default App;

import { Link } from "react-router-dom";

export default function StandardLink({
  children,
  classes,
  disable,
  to,
}: {
  classes?: string;
  disable?: boolean;
  children: React.ReactNode;
  to: string;
}) {
  return disable ? (
    <div>{children}</div>
  ) : (
    <Link to={to} className={classes || ""}>
      {children}
    </Link>
  );
}

import modalStyles from "../../../styles/modal.module.css";

export default function ModalTitle({
  titleContent,
  classes
}: {
  titleContent: string;
  classes?: string;
}) {
  return (
    <p className={`${modalStyles.modalTitle} ${classes || ""}`}>
      {titleContent}
    </p>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { getRoles } from "./action";
import { Role } from "../../types/users.types";

interface RoleSlice {
  roles?: Role[];
  error?: Error | null;
}

const initialState: RoleSlice = {
  error: null
};

const rolesSlice = createSlice({
  name: "roles-list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {
      delete state.error;
      delete state.roles;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.error = action.error as Error;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.roles = action.payload;
      }
    });
  }
});

export default rolesSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { Product } from "../../types/users.types";
import { getProducts } from "./action";
import ProductService from "../../service/productService";

interface ProductsSlice {
  products?: Product[];
  arrivalProducts?: Product[];
  departureProducts?: Product[];
  transferProducts?: Product[];
  error?: Error | null;
}

const initialState: ProductsSlice = {
  error: null
};

const productsSlice = createSlice({
  name: "product-list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      delete state.error;
      delete state.products;
      delete state.arrivalProducts;
      delete state.departureProducts;
      delete state.transferProducts;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.error = action.error as Error;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.products = action.payload;

        const { arrivalProducts, departureProducts, transferProducts } =
          ProductService.categorizeProducts(action.payload);

        state.arrivalProducts = arrivalProducts;
        state.departureProducts = departureProducts;
        state.transferProducts = transferProducts;
      }
    });
  }
});

export default productsSlice.reducer;

import textfieldStyles from "../../../styles/inputfield.module.css";
import { ReactComponent as MandatoryIcon } from "../../../assets/icons/bagstage_mandatory_star.svg";

export default function InputFieldNumber({
  width,
  disable,
  onChange,
  label,
  value,
  placeholder,
  error,
  helperText,
  required
}: {
  width?: string;
  disable?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value?: number;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
}) {
  return (
    <label
      className={textfieldStyles.inputFieldLabel}
      style={{ width: `${width}px` }}
    >
      <span className="flex">
        {label}
        {required && <MandatoryIcon />}
      </span>
      <input
        className={`
            ${textfieldStyles.inputField}
            ${error ? textfieldStyles.inputFieldError : ""}
          `}
        type="number"
        value={value}
        onChange={onChange}
        disabled={disable}
        placeholder={placeholder}
      />
      <span
        className={`
          ${textfieldStyles.helperText}
          ${error ? textfieldStyles.helperTextError : ""}
          `}
      >
        {helperText}
      </span>
    </label>
  );
}

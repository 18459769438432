import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../types/users.types";
import { getUsers } from "./action";

interface UserSlice {
  users?: User[];
  error?: Error | null;
}

const initialState: UserSlice = {
  error: null
};

const usersSlice = createSlice({
  name: "users-list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      delete state.error;
      delete state.users;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.error = action.error as Error;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.users = action.payload;
      }
    });
  }
});
export default usersSlice.reducer;

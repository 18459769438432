import loaderStyles from "../../styles/loader.module.css";
import modalStyles from "../../styles/modal.module.css";

export default function Loader() {
  return (
    <div className={modalStyles.modalOverlay}>
      <div className={loaderStyles.loader}></div>
    </div>
  );
}

import wrapperStyles from "../../styles/subPageWrapper.module.css";

export default function SubPageWrapper({
  children,
  classes
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
}) {
  return (
    <section className={`${wrapperStyles.subPageWrapper} ${classes || ""}`}>
      {children}
    </section>
  );
}

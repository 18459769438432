import { useState, useEffect, useRef } from "react";
import Keycloak from "keycloak-js";
import { setIsLogin, setKcObject } from "../store/auth";
import { useAppDispatch } from "../store/hooks";

const useAuth = () => {
  const isRun = useRef(false);
  const [login, setLogin] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // initialising keycloak
  useEffect(() => {
    // keycloak specific.
    if (isRun.current) return;
    isRun.current = true;

    // To do - create and add ENV variables.
    const client = new Keycloak({
      url: window._env_.REACT_APP_KC_URL,
      realm: window._env_.REACT_APP_KC_REALM!,
      clientId: window._env_.REACT_APP_KC_CLIENT_ID!
    });

    client
      .init({
        onLoad: "check-sso"
      })
      .then((res) => {
        setLogin(res);
        dispatch(setIsLogin(res));
        dispatch(setKcObject(client));
      });
  }, [dispatch]);

  return { login };
};

export default useAuth;

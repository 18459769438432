import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";

export enum actionTypes {
  getCompanies = "getCompanies"
}

export const getCompanies = createAsyncThunk(
  actionTypes.getCompanies,
  async () => {
    const response = await api.get(API_PATHS.getCompanies);
    return response.data;
  }
);

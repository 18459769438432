import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { API_PATHS } from "../../service/api";

export enum actionTypes {
  getRoles = "getRoles"
}

export const getRoles = createAsyncThunk(actionTypes.getRoles, async () => {
  const response = await api.get(API_PATHS.getRoles);
  return response.data;
});
